<template>
  <div id="news">
    <app-menu></app-menu>
    <admin-list-component
        title="Aktuality"
        add-text="Přidat Aktualitu"
        edit-path="/admin/news/edit/"
        entity-name="article"
        :get-name-function="getName"
        draggable="true"
    />
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import AdminListComponent from "@/components/admin/AdminListComponent";

export default {
  name: "adminNews",
  components: {
    'app-menu': AdminMenu,
    'admin-list-component': AdminListComponent
  },
  methods: {
    getName: function (article) {
      return article.title;
    }
  }
}
</script>

<style scoped>
#news {
  background-color: white;
  min-height: 100vh;
  overflow: hidden;
}

</style>
